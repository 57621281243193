
export default {
    data: () => ({
        bhlx:[],
        bhjz:[],
        chjg:[],
    }),
    computed: {

    },
    methods: {
        //获取字典数据
        getZD:function(){
            new Chain().link(function (that, next) {
                that.$get(`${that.getGetters('comUrl')}/dict/bhlx`, data => {
                    that.bhlx =data;
                    next();
                    });
                }).link(function (that, next) {
                    that.$get(`${that.getGetters('comUrl')}/dict/bhjz`, data => {
                        that.bhjz =data;
                        next();
                    });
                }).link(function (that, next) {
                    that.$get(`${that.getGetters('comUrl')}/dict/chjg`, data => {
                        that.chjg =data;
                        next();
                    });
                }).run(this);
        },
        //过滤字典
        filterStatus:function(arr,val){
            let status =  arr.filter(function(item){
                return item.key == val ;
            })
            if(status != ''){
                return status[0]; //返回筛选的对象
            } else{
                return {};
            }
        },
    },
    mounted() {
        this.getZD()
    }


}
