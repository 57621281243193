//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DICTIONARY from '@mixins/dictionary.js'

export default {
    mixins: [ DICTIONARY ],
    props:{
        tablList:{
            type:Array,
            default:() =>[]
        },
        settItem:{
            type:Object,
            default:() =>{}
        },
        parentType:{
            type:String,
            default:'', 
        }
    },

    components:{},
    data: function(){
        return{
            statusList:[
                {value:'未结算', key:'0',type:'info'},
                {value:'已结算', key:'1',type:'success'},
                {value:'确认中', key:'2',type:'warning'},
                {value:'已确认', key:'3',type:'success'}
            ],
            isChangneiList:[
                {value:'否', key:'0',type:'primary'},
                {value:'是', key:'1',type:'success'},
            ]
        }
    }, 
    computed:{
       
    },
    watch:{

    },

    methods: {


    },
    mounted() {

    }
}
