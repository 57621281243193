//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import commonTable from '../../components/common_table.vue'
export default {
    components: {commonTable},
    props: {},
    data(){
        return{
            detailData:{},
            tableData:[],
        }
    },
    computed: {
        rowguid:function(){
            return this.getQuery('rowguid')
        }  
    },
    watch:{
        
    },
    methods: {
        getDetail:function(){
            this.$get(this.$store.getters.settUrl + '/settItemChild/'+ this.rowguid, {}, function(data) {
                this.detailData = data.settItemChild;
                this.tableData = data.guaOrderList;
            })
        },
        //导出
        exportList:function(){
            if(this.tableData.length !=0){
                var search = toSearch({
                    isExport: true,
                    token:this.getQuery('token') ? this.getQuery('token') : this.getGetters('user').token
                });
                window.open('/bhtong/settmg/settItemChild/' + this.rowguid + search);
            }else{
                ShowMsg('当前清单暂无数据，暂不支持导出', 'warning');
            }
        },
        goBack:function(){
            this.$router.go(-1)
        }
      
    },
    mounted:function(){
        this.getDetail();   
    }
}
