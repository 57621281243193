//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LIST_MIXIN from '@mixins/list-page'
import uploadpz from './uploadpz.vue'



export default {
    mixins: [ LIST_MIXIN ],
    props:[],
    components:{uploadpz},
    data: function(){
        return{
            pgData:{
                appStartDate:'',
                appEndDate:'',
            },
            tableData:[],
            isConfirmList:[
                {value:'未确认',key:'0'},
                {value:'已确认',key:'1'},
            ],
            isSettList:[
                {value:'未结算',key:'0'},
                {value:'已结算',key:'1'},
            ],
            isInvoiceList:[
                {value:'未开票',key:'0'},
                {value:'已开票',key:'1'},
            ],

            dialogUploadpzVisible:false,
            dialogPingzhengVisible:false,
            formTitle:'',
            rowguid:'',
            itemName:'',
            imgurl:'',
            requestUrl:'/bhtong/settmg/settItemChild/sett',
            jspz:false
        }
    }, 
    computed:{
        valuetime:{
            get:function(){
                var startTime = this.pgData.appStartDate || '';
                var endtime = this.pgData.appEndDate || '';
                    if(startTime && endtime){
                        return[
                            startTime,
                            endtime
                        ];
                    }else{
                        return[]
                    }
            },
            set:function(val){
                if(!val){
                    this.pgData.appStartDate = '' ;
                    this.pgData.appEndDate = '';
                }else{
                    var startTime = val[0] || '';
                    var endtime = val[1] || '';
                    this.pgData.appStartDate = startTime;
                    this.pgData.appEndDate = endtime;
                }
            },
        }
    },
    watch:{
        dialogUploadpzVisible: function(n, o){
            if(!n){
                this.doSearch();
            }
        },
    },

    methods: {
        doSearch:function(){
            this.$refs.page.queryData();
        },
        afterQuery:function(){
            this.tableHeight = (window.innerHeight - 245);
        },
        //跳转详情
        goDetail:function(row){
            if(this.getQuery('token')){
            this.goto({
                path: './detail',
                query: {
                    rowguid: row.rowguid,
                    token:this.getQuery('token')
                }
            })
            }else{
                this.goto({
                    path: './detail',
                    query: {
                        rowguid: row.rowguid
                    }
                }) 
            }
        },
        //财务确认
        uploadPz:function(row){
            this.dialogUploadpzVisible = true;
            this.formTitle = '财务确认';
            this.rowguid = row.rowguid;
            this.itemName = row.itemName;
        },
        //查看凭证
        watckPz:function(row){
            var search = toSearch({
                token:this.getQuery('token') ? this.getQuery('token') : this.getGetters('user').token
            });
            this.imgurl =  '/bhtong/' + row.settFile + search
            if(row.settFile){
                //结算凭证
                this.jspz = true;
            }
            this.formTitle = '查看凭证';
            this.dialogPingzhengVisible = true
        },
        //确认清单
        affirmList:function(row){
            var that = this;
            ShowConfirm('是否确认当前清单' , 'warning', function() {
                that.$post(that.$store.getters.settUrl + '/settItemChild/confirm',{
                    rowguid:row.rowguid
                },function(data,res){

                    ShowMsg('确认成功','success');
                    that.doSearch();
                })
            }, function() {

            })
        },
        //下载发票
        downloadfp:function(row){
            window.open(row.invoiceFile);
        },
        //导出
        exportList:function(){
            // if(this.tableData.length != 0){
            //     var search = toSearch({
            //         isExport: true,
            //         token:this.getQuery('token') ? this.getQuery('token') : this.getGetters('user').token
            //     });
            //     window.open('/bhtong/settmg/settItemChild' + search);
            // }else{
            //     ShowMsg('当前清单暂无数据，暂不支持导出', 'warning');
            // }
            var search = toSearch({
                parentName:this.pgData.parentName,
                appName:this.pgData.appName,
                isConfirm:this.pgData.isConfirm,
                isSett:this.pgData.isSett,
                isInvoice:this.pgData.isInvoice,
                appStartDate:this.pgData.appStartDate,
                appEndDate:this.pgData.appEndDate,
                isExport: true,
                token:this.getQuery('token') ? this.getQuery('token') : this.getGetters('user').token
            });
            window.open('/bhtong/settmg/settItemChild' + search);
        }
    },
    mounted() {
        
    }
}
