//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {

     props: {
        rowguid: {
            type: String,
            default: ''
        },
        qdname: {
            type: String,
            default: ''
        },
        requesturl:{
            type: String,
            default: '/bhtong/settmg/settItemChild/sett'
        },
        appId:{
            type: String,
            default: '',
        },
        isHcgConfirm:{
            type: Boolean,
            default: false,
        }
    },
    components:{},
    data: function(){
        return{
            form:{
                file:'',
            },
            dwDetail:{},
        }
    }, 
    computed:{
        
    },
    watch:{
      
    },

    methods: {
        submit:function(){  
            this.$post(this.requesturl,{
                rowguid:this.rowguid,
                settFile:this.form.file,
            },function(data,res){
                if(res.code == 'v'){
                    this.cancle();
                    ShowMsg('提交成功','success');
                }else{
                    ShowMsg(res.msg,'warning');
                }
            })
        },
        cancle:function(){
            this.$emit('close');
        },
        getfile:function(val){
            this.form.file = val.fileUrl;
        }
    },
    mounted() {
        var that = this;
        if(this.isHcgConfirm && !!this.appId){
            this.$get(this.$store.getters.settUrl + '/hcgSettItem/getAppDwInfo',{
                appId:this.appId
            },function(data,res){
                that.dwDetail = data;
            })
        }
    }
}
